import { API, isLocalhost } from "@devfolioco/applynow-helpers";
import { ApplyButton, Overlay } from "./frames";

// Secure connection check! Only, allow localhost or https: domains.
if (window.location.protocol !== "https:" && !isLocalhost()) {
  throw new Error("Could not initialize the Apply Now script over a non-secure connection.");
}

const ButtonFramesCount = {
  available: 0,
  loaded: 0,
  hackathonData: null,
  hasSentHackathonData: false,
} as {
  available: number;
  loaded: number;
  hackathonData: any;
  hasSentHackathonData: boolean;
};

// This is proxy for Button frames that keeps track of the buttons that have loaded
// if all the buttons have loaded and the hackathon data is available, then we will
// send the hackathon data to the frames.
const ButtonFramesCountProxy = new Proxy(ButtonFramesCount, {
  set: function (target, property, value) {
    target[property] = value;

    const sendHackathonData = () => {
      ApplyButton.postMessage("SEND_HACKATHON_DATA", target.hackathonData);
      Overlay.postMessage("SEND_HACKATHON_DATA", target.hackathonData);
      target.hasSentHackathonData = true;
    };

    if (property === "loaded" && target.available === value && target.hackathonData && !target.hasSentHackathonData) {
      sendHackathonData();
    } else if (property === "hackathonData" && target.available === target.loaded && value && !target.hasSentHackathonData) {
      sendHackathonData();
    }
    return true;
  },
});

async function main(): Promise<void> {
  try {
    // Get the hackathon slug from element.
    const hackathonSlug = await getHackathonSlug();

    const markedButtons = getMarkedButtons();
    // Set the number of buttons available. This will be used to check later
    // if all the buttons have loaded in the proxy.
    ButtonFramesCountProxy.available = markedButtons.length;

    // Replace the specified/marked button nodes with iframes. We will search for elements with
    // the class-name "apply-button" and replace 'em with an iframe that loads the 'Apply with Devfolio'
    // button. Later on, the hackathon information and UI states will be communicated to these frames.
    markedButtons.forEach((buttonNode) => {
      buttonNode?.parentNode?.replaceChild(ApplyButton.createFrame(buttonNode.dataset), buttonNode);
    });

    // Apply some ETHIndia specific modifications to their respective button iframes.
    applyEthIndiaSpecificModifications();

    // Inject the overlay container & frame to the doc.
    document.body.appendChild(Overlay.createFrame());

    // Get and send hackathon data to the frames.
    const hackathonData = await API.getHackathonData(hackathonSlug);
    ButtonFramesCountProxy.hackathonData = hackathonData;

  } catch (error) {
    // If fetching the hackathon data fails as a result of a TypeError: Failed to fetch, it is
    // safe to set the development mode on (which makes the apply button visible).
    if (error?.message === "Failed to fetch") {
      ApplyButton.postMessage("ENABLE_DEV_MODE");
    } else {
      console.log("(SDK)", error);
    }
  }
}

window.addEventListener("message", (message) => {
  const { isTrusted, data } = message;

  if (isTrusted && data?.type) {
    const overlay = Overlay.container();
    if (!overlay) {
      console.log("(SDK) Could not find the overlay container.");
      return;
    }

    switch (data.type) {
      case "BUTTON_IFRAME_LOADED":
        // This event is sent from the buttons when they have loaded, we increment the
        // loaded count in the proxy, which triggers the trap in the proxy, that checks
        // if all the buttons have loaded and the hackathon data is available.
        ButtonFramesCountProxy.loaded += 1;
        break;

      case "OPEN_OVERLAY":
        overlay.style.display = "block";
        break;

      case "CLOSE_OVERLAY":
        overlay.style.display = "none";
        break;

      default:
        console.log("(SDK) Received unexpected message.", message);
    }
  }
});

/** Return the node elements meant to be replaced with the button frame. */
function getMarkedButtons(): NodeListOf<HTMLElement> {
  return document.querySelectorAll(".apply-button");
}

function applyEthIndiaSpecificModifications() {
  const THEME_SPECIFIC_HEIGHT = {
    "ethindia-2022--no-shadow": "60px",
    "ethindia-2022--light-shadow": "64px",
    "ethindia-2022--dark-shadow": "64px",
    "ethindia-2023": "62px",
  };

  // Get all the devfolio button iframes
  const devfolioButtonIFrames = document.querySelectorAll(".devfolio-button-iframe") as NodeListOf<HTMLIFrameElement>;

  devfolioButtonIFrames.forEach((iframe) => {
    // Check for the width attribute and set the width of the iframe accordingly.
    const srcAttributeValue = iframe.getAttribute("src");
    if (srcAttributeValue !== null) {
      const url = new URL(srcAttributeValue);
      const width = url.searchParams.get("buttonWidth");
      const buttonThemeName = url.searchParams.get("buttonTheme");

      /**
       * Set the height of the ETHIndia-2022-themed buttons manually since -
       * 1. We don't want to affect the existing devfolio button iframes.
       * 2. We don't need to allow the user to control the height of the button.
       */
      if (buttonThemeName != null && Object.keys(THEME_SPECIFIC_HEIGHT).includes(buttonThemeName)) {
        iframe.style.height = THEME_SPECIFIC_HEIGHT[buttonThemeName];
      }

      /**
       * Set the width of the button to whatever the user has specified.
       */
      if (width) {
        iframe.style.width = `${width}px`;
      }
    }
  });
}

/** Read the data attributes from specified button nodes to get the hackathon slug.  */
function getHackathonSlug(): Promise<string> {
  return new Promise((resolve, reject) => {
    const buttonNodes = getMarkedButtons();

    if (buttonNodes.length > 0) {
      let hackathonSlug = buttonNodes[0].getAttribute("data-hackathon-slug");
      if (typeof hackathonSlug === "string" && hackathonSlug.length > 0) {
        resolve(hackathonSlug);
      }
    }

    reject("Could not find an element with the class name 'apply-button' that has a data-hackathon-slug attribute.");
  });
}

main();
