import * as CSS from "csstype";

export const applyButtonIframe: CSS.Properties = {
  padding: 0,
  border: 0,
  margin: 0,
  height: "44px",
  width: "320px",
};

export const overlayContainer: CSS.Properties = {
  position: "fixed",
  left: 0,
  top: 0,
  display: "none",
  width: "100%",
  height: "100%",
  zIndex: 2147483647,
};

export const overlayIframe: CSS.Properties = {
  position: "relative",
  height: "100%",
  width: "100%",
  display: "block",
  backgroundColor: "transparent",
  padding: 0,
  border: 0,
  margin: 0,
};
