import * as style from "./style";

export const ApplyButton = {
  // Class-name of the frames.
  _CLASSNAME: "devfolio-button-iframe",

  // The sandbox attributes for button frames.
  _SANDBOX: "allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation",

  /**
   * Creates & returns a frame containing the Apply with Devfolio button.
   * @param options
   */
  createFrame: function (options?) {
    const iframe = document.createElement("iframe");

    iframe.src = "__SDK_BASE_URL__" + "/button?" + new URLSearchParams(options).toString();
    iframe.title = "Apply with Devfolio";
    iframe.className = ApplyButton._CLASSNAME;
    Object.assign(iframe.style, style.applyButtonIframe);
    iframe.setAttribute("sandbox", ApplyButton._SANDBOX);

    return iframe;
  },

  allFrames: function (): NodeListOf<HTMLIFrameElement> {
    return document.querySelectorAll(`.${ApplyButton._CLASSNAME}`);
  },

  /**
   * Send a message to all the button frames.
   * @param type type of message
   * @param data the message payload
   */
  postMessage: function (type, data?) {
    ApplyButton.allFrames().forEach((frame, id) => {
      frame?.contentWindow?.postMessage({ type, payload: data }, "*");
    });
  },
};

export const Overlay = {
  // ID of the overlay frame.
  _ID: "devfolio-overlay-iframe",

  _CONTAINER_ID: "devfolio-overlay-container",

  // The sandbox attributes for frames.
  _SANDBOX:
    "allow-scripts allow-same-origin allow-top-navigation allow-top-navigation-by-user-activation allow-forms allow-popups",

  // Set to true once the overlay frame has been loaded.
  hasLoaded: false,

  /**
   * Creates & returns a frame containing the Overlay.
   */
  createFrame: function (): HTMLDivElement {
    const iframe = document.createElement("iframe");
    iframe.src = "__SDK_BASE_URL__" + "/overlay" + window.location.search;
    iframe.title = "Continue your hackathon application";
    iframe.id = Overlay._ID;
    Object.assign(iframe.style, style.overlayIframe);
    iframe.setAttribute("sandbox", Overlay._SANDBOX);

    iframe.addEventListener("load", () => {
      Overlay.hasLoaded = true;
    });

    // The wrapper which holds the overlay frame.
    const overlayContainer = document.createElement("div");
    overlayContainer.id = Overlay._CONTAINER_ID;
    Object.assign(overlayContainer.style, style.overlayContainer);
    overlayContainer.appendChild(iframe);

    return overlayContainer;
  },

  frame: function (): HTMLIFrameElement | null {
    return document.querySelector(`#${Overlay._ID}`);
  },

  container: function (): HTMLDivElement | null {
    return document.querySelector(`#${Overlay._CONTAINER_ID}`);
  },

  /**
   * Send a message to the overlay frame.
   * @param type type of message
   * @param data the message payload
   */
  postMessage: function (type, data) {
    const frame = Overlay.frame();

    function sendMessage() {
      frame?.contentWindow?.postMessage({ type, payload: data }, "*");
    }

    if (Overlay.hasLoaded) {
      sendMessage();
    } else {
      frame?.addEventListener("load", sendMessage);
    }
  },
};
